:root {
  font-size: 14px;

  --primary-color-main: #3a3657;
  --primary-color-dark: #1e1740;
  --primary-color-light: #d8d5d8;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: var(--primary-color-light);
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary-color-dark);
  border-radius: 20px;
  border: 0.1px solid transparent;
  background-clip: content-box;
}
html,
body {
  width: 100vw;
  max-width: 1800px;
  margin: auto;
  overflow-x: hidden;
}
body {
  background: #fcfcfc !important;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
